<div *ngIf="isSelected">
<div class="header">
    <h4><span> Request Tasks </span></h4>
</div>
<!-- <div class="filter-container">
    <div class="p-formgroup-inline">
        <div class="p-field">
            <div class="pb-1"> User Id</div>
            <input [ngModelOptions]="{ standalone: true }" type="text" [(ngModel)]="userId" pInputText />
        </div>
        <div class="button-container">
        <span style="margin: 0;position: absolute;top: 56%;-ms-transform: translateY(-50%);transform: translateY(-50%);"><button pButton type="button" label="Submit" (click)="getUser()"></button></span>
        </div>
    </div>
</div> -->

<div class="table">
<p-table #dt [columns]="cols" [value]="data" [paginator]="true" [rows]="50" [autoLayout]="true">
    <ng-template pTemplate="caption">
        <div style="display: flex; width: 100%;">
        <input  class="form-control" type="text" style="width: 250px;height: 34px;" pInputText size="50" placeholder="All Filter"
        (input)="dt.filterGlobal($event.target.value, 'contains')" lazy="false" /> 
            <div style="text-align: end ;padding-bottom: 22px; padding-right: 20px; width: 100%;">
            <button class="btn btn-danger" style="height: 33px;" (click)="deleteFinished()">Delete Finished</button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
              <th>
                  <ng-container [ngSwitch]="col.field">
                     <ng-container *ngSwitchDefault>{{ col.header }}</ng-container>
                   </ng-container>
              </th>
        </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
        <tr>
            <ng-container *ngFor="let col of columns" >
            <td>
                <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'startTimestamp'">
                      <span *ngIf="data.start"> {{ data.start.millis| date: "yyyy-MM-dd" }} at {{ data.start.millis | date: "shortTime" }}</span> 
                    </ng-container>
                    <ng-container *ngSwitchCase="'endTimestamp'">
                        <span *ngIf="data.end"> {{ data.end.millis | date: "yyyy-MM-dd" }} at {{ data.end.millis | date: "shortTime" }}</span> 
                      </ng-container>
                    <ng-container *ngSwitchCase="'responseRequest'">
                       {{data.responseCount}}/{{data.requestCount}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'sno'">
                           {{rowIndex +1 }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'taskId'">
                       <span (click)="openTask(data.taskId)" style="cursor: pointer;"> <u>{{data.taskId}}</u> </span>
                 </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{data[col.field]}}
                    </ng-container>
                </ng-container>
            </td>
            </ng-container>
        </tr>
    </ng-template>
</p-table>
</div>
</div>
<div *ngIf="!isSelected">
    <div style="display: flex; width: 100%;">
        <div style="padding: 20px; width: 100%;">
        <button class="btn btn-success" style="height: 33px;" (click)="back()"> <i class="fa fa-chevron-left"></i> Back</button>
        </div>
    </div>
    <task-details [taskId]="taskId" [isSelected]="isSelected"></task-details>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "var(--white)" type = "timer" [fullScreen] = "true"><p style="color: var(--white)" > Loading... </p></ngx-spinner>

